import axios from 'axios';
import { getEnvironmentUrl } from '@/services/environment.helper.js';


const Api = axios.create({
  baseURL: getEnvironmentUrl(),
  //timeout: 1000,
  //headers: { 'X-Custom-Header': 'foobar' }
});

Api.defaults.headers.common['Content-Type'] = 'application/json';


export default Api; 
