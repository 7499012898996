<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left mt-3">
        <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto Logo" />
      </a>
      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Cadastro grátis</h2>
        </div>
        <div class="card-body">
          <h4 class="mt-0 mb-4 font-weight-bold">1. Informações básicas<br />
            <small class="text-muted">Informe seus dados pessoais, ou de sua empresa.</small>
          </h4>
          <form action="/auth/login" id="form" method="post">
            <div class="form-group mb-3">
              <label>Razão social</label>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Nome" type="text" @focus="removeError('Nome')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-user"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['Nome'] }}</span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">CNPJ</label>
              </div>
              <div class="input-group">
                <the-mask :mask="['###.###.###-##', '##.###.###/####-##']" type="tel" v-model="userData.CpfCnpj" class="form-control form-control-lg" @focus.native="removeError('CpfCnpj')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-address-card"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['CpfCnpj'] }}</span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Inscrição estadual (opcional)</label>
              </div>
              <div class="input-group">
                <input type="text" v-model="userData.RGIE" class="form-control form-control-lg" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-id-badge"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Nome fantasia</label>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.ApelidoRazaoSocial" type="text" @focus="removeError('ApelidoRazaoSocial')"/>
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-comment-dots"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{ errors['ApelidoRazaoSocial'] }}</span>
            </div>
            <div class="row">
              <div  class="col-sm-12" >
                <p class="mt-3 float-left">Já sou cadastrado. <router-link to="/auth">Entrar!</router-link></p>
                <button type="button" @click="proximo()" class="btn btn-primary mt-2 float-right" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Próximo'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
import api from '@/services/external.api.js';
import {getCurrentUser,setCurrentUser,userEmpty } from '@/services/external.helpers.js';
import { logout } from '@/services/user.service.js';

export default {
    data() {
        return {
          userData: userEmpty(),
          errors: [],
          loading: false
        };
    },
    created() {
      let that = this;
      this.$root.authenticated = false;
      logout();
      this.userData = getCurrentUser();

      if (this.$route.params.id) {
        api.get('/external/lead/'+this.$route.params.id)
          .then(response => {
            if (response.data) {
              that.userData.Email = response.data.Email;
              that.userData.Celular = response.data.Celular;
            }
            
          });
      }
        
    },
    methods: {
      proximo: function () {
        if (this.validar()) {
          setCurrentUser(this.userData);
          this.$router.push('/cadastro/endereco');            
        }
        else {
          window.scrollTo(0,0);
        }
        
      },
      validar() {
        this.errors = {};

        if (this.userData.Nome.length <= 2) {
          this.errors['Nome'] = 'Digite corretamente o nome ou razão social.';
        }
        if (!this.validaCpfCnpj(this.userData.CpfCnpj)) {
          this.errors['CpfCnpj'] = 'Cpf ou CNPJ inválido.';
        }

        if (this.userData.ApelidoRazaoSocial.length <= 2) {
          this.errors['ApelidoRazaoSocial'] = 'Digite corretamente o nome fantasia da empresa.';
        }

        return Object.keys(this.errors).length === 0;
      },
      validaCpfCnpj(val) {
          if (val.length == 11) {
              var cpf = val.trim();
     
              cpf = cpf.replace(/\./g, '');
              cpf = cpf.replace('-', '');
              cpf = cpf.split('');
        
              var v1 = 0;
              var v2 = 0;
              var aux = false;
        
              for (var i = 1; cpf.length > i; i++) {
                  if (cpf[i - 1] != cpf[i]) {
                      aux = true;   
                  }
              } 
        
              if (aux == false) {
                  return false; 
              } 
        
              for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
                  v1 += cpf[i] * p; 
              } 
        
              v1 = ((v1 * 10) % 11);
        
              if (v1 == 10) {
                  v1 = 0; 
              }
        
              if (v1 != cpf[9]) {
                  return false; 
              } 
        
              for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
                  v2 += cpf[i] * p; 
              } 
        
              v2 = ((v2 * 10) % 11);
        
              if (v2 == 10) {
                  v2 = 0; 
              }
        
              if (v2 != cpf[10]) {
                  return false; 
              } else {   
                  return true; 
              }
          } else if (val.length == 14) {
              var cnpj = val.trim();
        
              cnpj = cnpj.replace(/\./g, '');
              cnpj = cnpj.replace('-', '');
              cnpj = cnpj.replace('/', ''); 
              cnpj = cnpj.split(''); 
        
              var v1 = 0;
              var v2 = 0;
              var aux = false;
        
              for (var i = 1; cnpj.length > i; i++) { 
                  if (cnpj[i - 1] != cnpj[i]) {  
                      aux = true;   
                  } 
              } 
        
              if (aux == false) {  
                  return false; 
              }
        
              for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
                  if (p1 >= 2) {  
                      v1 += cnpj[i] * p1;  
                  } else {  
                      v1 += cnpj[i] * p2;  
                  } 
              } 
        
              v1 = (v1 % 11);
        
              if (v1 < 2) { 
                  v1 = 0; 
              } else { 
                  v1 = (11 - v1); 
              } 
        
              if (v1 != cnpj[12]) {  
                  return false; 
              } 
        
              for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
                  if (p1 >= 2) {  
                      v2 += cnpj[i] * p1;  
                  } else {   
                      v2 += cnpj[i] * p2; 
                  } 
              }
        
              v2 = (v2 % 11); 
        
              if (v2 < 2) {  
                  v2 = 0;
              } else { 
                  v2 = (11 - v2); 
              } 
        
              if (v2 != cnpj[13]) {   
                  return false; 
              } else {  
                  return true; 
              }
          } else {
              return false;
          }
      },
      removeError(prop) {
        this.errors[prop] = '';
        delete this.errors[prop];

        this.$nextTick(() => {
          let obj = new Object();
          for (var i in this.errors) {
            obj[i] = this.errors[i];
          }
          this.errors = obj;
        });
      },

    },
}

</script>
